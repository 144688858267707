import styled from 'styled-components';
import variables, { fs, screenSize, mq } from '../../utils/variables';

export const HeroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1420px;
  /* min-height: calc(70vh -  200px); */
  padding: 10vh 20px 3vh;
  margin: 0 auto;
  text-align: center;

  ${mq('xxxl')} {
    /* min-height: calc(70vh - 400px); */
  }

  .content {
    max-width: 1140px;
    padding: 0 20px;
    margin: 0 auto 50px;
  }

  .title {
    font-family: 'Bossa', sans-serif;
    font-weight: 700;
    color: ${variables.colors.white};

    ${fs(46)};
  }

  .text {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    color: ${variables.colors.white};
    line-height: 1.5;
    margin-left: auto;
    margin-right: auto;

    ${fs(20)}
    ${screenSize('max-width', 1000)}

    & + .subtext {
      margin-top: 30px;
    }
  }
`;

export const FeaturedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* max-width: 1420px; */
  /* padding: 0 20px; */
  margin: 0 auto;

  padding: 90px 5vw 0;

  ${mq('l')} {
    min-height: 'none';
    padding: 45px 35px ${props => props.styleType === 'pricing' ? '20vh' : '50px'};
  }

  ${mq('s')} {
    padding: 45px 20px 50px;
  }


  .featured {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: flex-start;
    padding: 0 0 2.1vw;
    width: 100%;

    /* ${screenSize('margin-bottom', 200)}; */

    ${mq("m")} {
      flex-direction: column;
      /* ${screenSize('margin-bottom', 0)}; */
    }

    &-content {
      max-width: 35%;
      min-width: 35%;
      /* padding-left: 7%; */
      padding-right: 2%;
      padding-top: 5vw;

      ${mq("l")} {
        max-width: 60%;
      }

      ${mq("m")} {
        max-width: none;
        min-width: 0;
        padding-left: 0;
        padding-right: 0;
        margin: 0 20px;
      }

      &-block {
        margin-left: 15%;

        ${mq("m")} {
          margin-left: 0;
        }
      }

      h2 {
        font-family: 'Bossa', sans-serif;
        font-weight: 500;
        color: ${variables.colors.green};
        ${fs(24)};
      }

      h3 {
        font-family: 'Bossa', sans-serif;
        font-weight: 500;
        color: ${variables.colors.white};
        ${fs(36)};
      }

      a.large {
        ${mq("m")} {
          width: 100%;
        }
      }

      .author {
        display: flex;
        align-items: center;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        color: ${variables.colors.white};
        ${fs(16)};

        a {
          display: inline-flex;
          align-items: center;
          font-family: 'Montserrat', sans-serif;
          font-weight: 500;
          color: ${variables.colors.white};
          ${fs(18)};
          text-decoration: none;
          transition: color .3s ease-in-out;

          &:hover {
            color: ${variables.colors.red};
          }
        }

        .avatar {
          display: inline-block;
          width: 40px;
          height: 40px;
          object-fit: cover;
          border: 1px solid ${variables.colors.white};
          border-radius: 50%;
          margin: 0 8px;
        }
      }
    }
    &-visual {
      flex: 1;
      position: relative;
      /* max-width: 50%; */
      opacity: 1;
      cursor: none;
      margin-bottom: -6vw;
      overflow:hidden;

      border: 1px solid ${variables.colors.backgroundSuperDark};
      background: ${variables.colors.backgroundSuperDark} none;
      /* transform: translate(15%, 0); */

      box-shadow: 0px 24px 84px rgba(2, 2, 22, 0.8);
        
      // ${screenSize('border-radius', 16)};
      // ${screenSize('border-width', 16)};

      border-radius: 24px;
      border-width: 15px;

      &::before {

      }

      ${mq("l")} {
        margin-bottom: 0;
      }

      ${mq("m")} {
        max-width: none;
        /* padding-bottom: 15%; */
        transform: translate(0, 0);
        margin: 40px 20px;
      }

      &-inner {
        overflow:hidden;
        // ${screenSize('border-radius', 12)};
        border-radius: 15px;
      }

      iframe,
      img {
        display: block;
        width: 100%;
        max-width: 100%;
        margin: 0;
        
      }

      .picture {
        object-fit: cover;

        ${mq("m", true)} {
          display: none;
        }
      }

      .viewer {
        ${mq("m")} {
          display: none;
        }
      }
    }
  }

`;