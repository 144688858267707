import React, { useState, createRef, useEffect } from 'react';

import { Container, Content, Visual } from './styled.js';

const CommunityNewsletter = (props) => {
  
  let communityNLFormEl = createRef();

  const [communityNLFormAdded, setCommunityNLForm] = useState(false);

  useEffect(() => {
    if (!communityNLFormAdded && window && communityNLFormEl.current) {
      addScript(communityNLFormEl.current)
    }
  }, []);


  const addScript = el => {
    const scriptExternal = document.createElement("script")
    scriptExternal.src = "//js.hsforms.net/forms/v2.js"
    el.appendChild(scriptExternal)

    if (window.hbspt && el) {
      addCommunityNLForm(el);
    } else {
      setTimeout(() => {
        addScript(el);
      }, 200)
    }
  }

  const addCommunityNLForm = el => {
    setTimeout(() => {
      const scriptCode = document.createElement("script")
      scriptCode.innerHTML = `
      hbspt.forms.create({
        portalId: "7066418",
        region: "na1",
        formId: "c0c84c12-a57f-4d75-a273-1b3027a782e2"
      });
      `
      el.appendChild(scriptCode)
      setCommunityNLForm(true)
    }, 0)
  }

  return (
    <Container>
      <Content>
        <h2>Come and join the Community</h2>
        <p className="intro">Be part of the Community and do this and that because it’s cool and you can be beautiful and famous</p>
        <h3>Why should I join?</h3>
        <p>By joining Community, you can share your work to others for them to learn, be inspired, clone and play with your own creation.</p>
        <h3>What’s next?</h3>
        <p>We have many ideas for Community and we’re spending ressources to make that happen.</p>
        <ul>
          <li>we’re creating a business directory where you’ll be able to work directly with potential client</li>
          <li>we‘re building a place where you’ll be able to create and sell templates and presentation</li>
          <li>we want you to have a showcase page so anybody can see what you’ve accomplished</li>
        </ul>
        <p>We’re getting there and we can’t wait to ship that for you!</p>
        <h3>Want to stay in the loop?</h3>

        <p>Subscribe to our Community newsletter</p>

        <div className="form-wrapper" ref={communityNLFormEl} />
      </Content>
      <Visual>
        <img src="" alt=""/>
      </Visual>
    </Container>
  )
}

export default CommunityNewsletter;
