import React, { useState, useEffect, useRef } from "react"
import { graphql } from "gatsby"
import bodymovin from "lottie-web"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Ready from "../components/ready"

import illuReady from "../images/ready-community.svg"
import illuNewsletter from "../images/community-newsletter-illu.svg"
import visualNewsletter from "../images/community-newsletter-visual.png"

import { Section } from "../components/common/section"
import CommunityHero from "../components/community-hero"

import CommunityGallery from "../components/community-gallery"
// import CommunityInterview from "../components/community-interview"
import CommunityNewsletter from "../components/community-newsletter"

export const query = graphql`
  query CommunityQuery {
    allAirtable(
      filter: { table: { eq: "Gallery" }, data: {} }
      sort: { fields: [data___Order], order: ASC }
    ) {
      edges {
        node {
          data {
            Order
            Title
            Featured
            Url
            Screenshot {
              url
            }
            PictureName
            Tags
            Description
            AuthorName
            AuthorWebsite
            AuthorAvatar {
              url
            }
            AuthorPictureName
            CloneUrl
          }
        }
      }
    }
  }
`

const CommunityFormPage = ({ data }) => {
  let animationBackground = useRef()

  const [currentBreakPoint, setCurrentBreakpointChildren] = useState({
    type: null,
    bp: null,
  })

  const featureItem = () => {
    const featuredItems = data.allAirtable.edges.filter(
      item => item.node.data.Featured === true
    )
    return featuredItems[featuredItems.length - 1].node.data
  }

  const getAllTags = () => {
    let output = []
    data.allAirtable.edges.map(item => {
      if (item.node.data !== featureItem()) {
        if (item.node.data.Tags) {
          item.node.data.Tags.map(tag => {
            if (!output.includes(tag)) {
              output.push(tag)
            }
          })
        }
      }
    })
    return output
  }

  useEffect(() => {
    if (
      animationBackground &&
      animationBackground.current &&
      !animationBackground.current.loaded
    ) {
      animationBackground.current.loaded = true
      bodymovin.loadAnimation({
        useSubFrames: false,
        wrapper: animationBackground.current,
        animType: "svg",
        autoplay: true,
        loop: false,
        path: "/animations/Content-shape-left.json",
      })
    }
  }, [currentBreakPoint])

  return (
    <Layout
      headerDark={true}
      socialDark={true}
      currentPage="community"
      updateBreakpoint={setCurrentBreakpointChildren}
    >
      <SEO title="Ludus Gallery" />

      <Section dark={true}>
        <CommunityHero
          dark={true}
          title="Ludus Gallery"
          text="Bored of scrolling Pinterest for inspiration? Annoyed by wordy slides?<br />Ludus Gallery is  the place to look for ideas, tips, tricks to make your presentation look great. <br />And sometimes, you can even use some other people template.  "
          featured={featureItem()}
        />
      </Section>

      <Section>
        <CommunityGallery
          cards={data.allAirtable.edges}
          tags={getAllTags()}
          featured={featureItem()}
        />
      </Section>

      {/* <Section>
        <CommunityInterview posts={data.allHubspotPost.edges} />
      </Section> */}

      <Section>
        <CommunityNewsletter />
        <div
          style={{
            display:
              currentBreakPoint.bp === "m" ||
              currentBreakPoint.bp === "s" ||
              currentBreakPoint.bp === "xs"
                ? "none"
                : "block",
            position: "absolute",
            zIndex: "0",
            top: "50%",
            left: "55%",
            width: "45%",
            paddingBottom: "35%",
            transform: "translate(0, -50%)",
            background: `transparent url(${visualNewsletter}) no-repeat 0 0`,
            backgroundSize: "auto 100%",
          }}
        />
        <div
          style={{
            display:
              currentBreakPoint.bp === "m" ||
              currentBreakPoint.bp === "s" ||
              currentBreakPoint.bp === "xs"
                ? "none"
                : "block",
            position: "absolute",
            zIndex: "0",
            width:
              currentBreakPoint.bp === "m" || currentBreakPoint.bp === "s"
                ? "0%"
                : "32%",
            height: "100%",
            top: "0",
            right: "0",
            background: `transparent url(${illuNewsletter}) no-repeat 100% 0`,
            backgroundSize: "contain",
          }}
        />
      </Section>

      <Section dark={true}>
        <div
          style={{
            display:
              currentBreakPoint.bp === "m" ||
              currentBreakPoint.bp === "s" ||
              currentBreakPoint.bp === "xs"
                ? "none"
                : "block",
            position: "absolute",
            zIndex: "0",
            width:
              currentBreakPoint.bp === "m" || currentBreakPoint.bp === "s"
                ? "0%"
                : "45%",
            paddingBottom:
              currentBreakPoint.bp === "m" || currentBreakPoint.bp === "s"
                ? "0%"
                : "45%",
            bottom: "0",
            left: "0",
            background: `transparent url(${illuReady}) no-repeat 0 100%`,
            backgroundSize: "contain",
          }}
        />

        <Ready dark={true} reverse={true} />
      </Section>
    </Layout>
  )
}

export default CommunityFormPage
