import styled from 'styled-components';
import variables, { mq, fs, screenSize } from '../../utils/variables';

export const Container = styled.div`
  display: flex;
  justify-content: ${props => props.alignOn === 'left' ? 'flex-start' : 'center'};
  align-items: center;
  margin: 0 auto;
  padding: ${props => props.styleType === 'pricing' ? '4vh' : '0px'} 5vw ${props => props.styleType === 'pricing' ? '20vh' : '50px'};

  ${mq('l')} {
    min-height: 'none';
    padding: 45px 35px ${props => props.styleType === 'pricing' ? '20vh' : '50px'};
  }

  ${mq('s')} {
    padding: 45px 20px 50px;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 554px;
  margin-left: 7%;
  ${screenSize('padding-top', 178)};
  ${screenSize('padding-bottom', 230)};

  ${mq("m")} {
    padding-top: 30px;
    padding-bottom: 40px;
    margin-left: 0;
  }

  h2 {
    font-family: 'Bossa', sans-serif;
    font-weight: 500;
    color: ${variables.colors.backgroundDark};
    ${fs(40)};
  }

  h3 {
    font-family: 'Bossa', sans-serif;
    font-weight: 500;
    color: ${variables.colors.backgroundDark};
    ${fs(24)};
  }

  ul {
    list-style-type: disc;
  }

  p,
  ul li {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    color: ${variables.colors.greyDarker};
    ${fs(18)};

    &.intro {
      ${fs(20)};
    }

    & + h3 {
      ${screenSize('margin-top', 55)};
    }
  }

  .form-wrapper {
    form {
      display: flex;
      overflow: hidden;

      .hs-form-field {
        width: 100%;

        label {
          /* display: none; */
          opacity: 0;
          position: absolute;
          top: -9999vw;
          left: -9999vw;
        }

        .hs-error-msgs {
          list-style-type: none;
          padding: 0;
          margin: 10px -48px 0 0;
          
          label {
            display: block;
            color: ${variables.colors.red};
            ${fs(14)}
            opacity: 1;
            position: relative;
            top: 0;
            left: 0;
          }
        }
      }
    }
    input[type=email] {
      width: 100%;
      height: 48px;
      padding: 0 1vw;
      border: 1px solid ${variables.colors.greyLight};
      background: ${variables.colors.white};
      border-right: 0;
      border-radius: 8px 0 0 8px;
      outline: none;
      color: ${variables.colors.backgroundDark};
      transition: border-color .3s ease-in-out;

      ${fs(16)}

      &:-webkit-autofill,
      &:-webkit-autofill:hover, 
      &:-webkit-autofill:focus {
        -webkit-appearance: menulist-button;
        background-color: transparent !important;
        background-image: none !important;
        color: ${variables.colors.white} !important;
        -webkit-text-fill-color: ${variables.colors.white} ;
        transition: background-color 5000s ease-in-out 0s;
      }

      ${mq('m')} {
        padding: 0 20px;
      }

      &::placeholder {
        color: ${variables.colors.grey};
      }

      &:focus {
        border-color: ${variables.colors.blue};
      }
    }

    input[type="submit"] {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;
      padding: 0;
      background-color: ${variables.colors.blue};
      background-image: url("data:image/svg+xml, %3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.3224 6.69899L21.3148 6.70198L1.28754 14.9864C1.11864 15.0556 0.969535 15.1656 0.85356 15.3066C0.737586 15.4476 0.658361 15.6151 0.622972 15.7942C0.583914 15.9817 0.592091 16.176 0.646767 16.3596C0.701444 16.5432 0.800903 16.7103 0.936198 16.8459L4.69127 20.6009C4.8765 20.786 5.1205 20.9007 5.38122 20.9252C5.64193 20.9497 5.90303 20.8825 6.11951 20.7352L15.2677 14.4421C15.3038 14.4176 15.3473 14.4063 15.3908 14.4104C15.4343 14.4145 15.475 14.4336 15.5059 14.4645C15.5367 14.4954 15.5559 14.5361 15.56 14.5796C15.564 14.623 15.5528 14.6666 15.5282 14.7027L9.23553 23.8505C9.08814 24.0669 9.02081 24.3278 9.04513 24.5885C9.06946 24.8491 9.18391 25.0931 9.36878 25.2784L13.1245 29.0342C13.2538 29.1636 13.4131 29.2589 13.5883 29.3116C13.7635 29.3642 13.949 29.3725 14.1282 29.3358C14.3443 29.2922 14.5428 29.1859 14.6989 29.0302C14.8072 28.9217 14.8928 28.7927 14.9505 28.6507L23.2681 8.65724L23.2714 8.64796C23.38 8.37552 23.4064 8.07721 23.3473 7.78993C23.2882 7.50265 23.1462 7.239 22.9388 7.03161C22.7314 6.82421 22.4677 6.68217 22.1804 6.62305C21.8932 6.56393 21.5949 6.59034 21.3224 6.69899Z' fill='%23FFFFFF'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: 60% 40%;
      background-size: 60%;
      border: 0;
      border-radius: 0 8px 8px 0;
      outline: none;
      text-indent: -9999px;
      cursor: pointer;
    }
  }
`;

export const Visual = styled.div`
  width: 100%;

  ${mq("m")} {
    display: none;
  }
`;