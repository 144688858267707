import React from 'react';
import LinkTransition from '../common/link-transition';
// import Lottie from 'react-lottie';

import Image from "../image"

import { HeroWrapper, FeaturedWrapper } from './styled.js';
import {Button} from '../common/button';

const ContentHero = (props) => {

  const featured = (props.featured) ? props.featured : null;

  return (
    <>
    <HeroWrapper>      
      <div className="content">
        {(props.title) ? (<h1 className="title" dangerouslySetInnerHTML={{__html: props.title}}></h1>) : null}
        {(props.text) ? (<div className="text" dangerouslySetInnerHTML={{__html: props.text}}></div>) : null}
      </div>

      {/* <LinkTransition to="/submit-your-presentation">Submit</LinkTransition> */}


      
    </HeroWrapper>
    {(featured) ? (
      <FeaturedWrapper>
      
        <div className="featured">

            <div className="featured-content">
              <div className="featured-content-block">
                <h2>The Ludus pick presentation</h2>

                <h3>{featured.Title}</h3>

                <p className="author">
                  by 
                  <a href={featured.AuthorWebsite[0]} target="_blank">
                    <img src={`/gallery-avatars/${featured.AuthorPictureName}`} className="avatar" />
                    {featured.AuthorName[0]}
                  </a>
                </p>

                {featured.CloneUrl ? (
                  <Button href={featured.CloneUrl} className="ghost" target="_blank">Clone in Ludus</Button>
                ) : featured.Url ? <Button href={featured.Url} className="ghost" target="_blank">View in Ludus</Button> : null}
                
              </div>
            </div>

            <div className="featured-visual">
              <div className="featured-visual-inner">
                <img src={`/gallery-posters/${featured.PictureName}`} className="picture" />
                <div className="viewer" style={{
                  position:'relative',
                  paddingBottom:'calc(56.25%)',
                  height:'0',
                  overflow:'hidden',
                  maxWidth:'100%'
                }}>
                  <iframe src={featured.Url} frameBorder="0" allowFullScreen style={{
                    position:'absolute',
                    top:'0',
                    left:'0',
                    width:'100%',
                    height:'100%'
                  }}></iframe> 
                </div>
              </div>
            </div>
            
            
          </div>
      
      </FeaturedWrapper>
    ) : null}
    </>
  );
};

export default ContentHero;